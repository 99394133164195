<template>
  <v-container class="py-0">
    <!-- start spiner --->
    <v-row align="center" class="pt-10" justify="center" v-if="isLoading">
      <v-progress-circular
        :size="70"
        :width="7"
        color="amber"
        indeterminate
      ></v-progress-circular>
    </v-row>
    <!-- end -->
    <VenueTemplate :venue-data="venueData"/>
  </v-container>
</template>
<script>
import { mapState } from 'vuex'
import VenueTemplate from '@/pages/Venues/VenueTemplate'
import eventInfo from '@/mixins/eventInfo'

export default {
  components: {
    VenueTemplate
  },
  async created () {
    try {
      // let phone
      // console.log('this.event.contacts')
      // console.log(this.event.contacts)
      // if (this.event.contacts && Array.isArray(this.event.contacts) &&
      // this.event.contacts.length) phone = this.event.contacts[0].phone
      const fltActnBtns = []
      fltActnBtns.push(
        {
          key: 'message',
          value: ''
        },
        {
          key: 'share',
          value: ''
        }
      )

      this.$store.commit('tempStates/SET_FLOATING_ACTION_BUTTONS', fltActnBtns)
    } catch (error) {
      console.error(error)
    }
  },
  mixins: [eventInfo],
  data () {
    return {
    }
  },
  computed: {
    ...mapState({
    }),
    venueData () {
      return {
        ...this.selectedEvent
      }
    }
  },
  methods: {
    getSelectedTicket (ticket) {
      this.selectedTicket = ticket
    }
  }
}
</script>
<style scoped>
/* sm */
@media only screen and (max-width: 600px) {
}
/* md */
@media only screen and (max-width: 960px) and (min-width: 600px) {
}
@media only screen and (max-width: 1264px) {
}
</style>
